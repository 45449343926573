import React from 'react'
import { Link } from 'react-router-dom'

function Nav() {
    return (
        <nav>

            <Link to="/turnosdia">
                <div className="btn btn-dark">Ver turnos</div>&nbsp;
            </Link>
            <Link to="/crearturno">
                <div className="btn btn-dark">Crear turno</div>&nbsp;
            </Link>
            <Link to="/buscarturno">
                <div className="btn btn-dark">Buscar turno</div>&nbsp;
            </Link>


        </nav>
    )
}

export default Nav;
