import React, { useState, useEffect } from 'react'
import Formcrearturno from '../formcrearturno/formcrearturno'
import { Redirect } from 'react-router'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ForcedLogOutAuthAction } from "../../redux/actions/AuthAction";

function Crearturno(props) {

    let history = useHistory();

    const { logout } = props

    const [isThereError, setisThereError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [cargando, setCargando] = useState(false)
    const [vehiculos, setVehiculos] = useState([])
    const [errorToken, setErrorToken] = useState(false)
    const [redirect, setRedirect] = useState("")

    const setError = (mensaje) => {
        setisThereError(true)
        setErrorMessage(mensaje)
    }

    const unsetError = () => {
        setisThereError(false)
        setErrorMessage("")
    }

    useEffect(() => {
        const obtenerVehiculos = async () => {

            unsetError()
            setCargando(true)

            let url = 'https://rtogodoycruz.reviturnos.com.ar/api/auth/tipVeh'

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Authorization': 'Bearer ' + user.access_token
            }


            fetch(url, { method: 'GET', headers })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {
                            setVehiculos(respuesta)
                            setCargando(false)
                        }

                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }
                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })
        }
        obtenerVehiculos()

    }, [])


    return (
        <div style={{ position: "relative", minHeight: "600px" }}>
            <h3>Crear turno</h3>
            <br />

            <div className="formulario">
                {vehiculos && <Formcrearturno tipos={vehiculos} />}


                {
                    isThereError && <>
                        <div style={{ position: "relative" }} className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div></>
                }
            </div>

            {redirect && <Redirect to={redirect} />}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(ForcedLogOutAuthAction(history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Crearturno);
