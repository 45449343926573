import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ForcedLogOutAuthAction } from "../../redux/actions/AuthAction";


function Reprogturno(props) {

    const { logout } = props

    let history = useHistory();


    const [cargando, setCargando] = useState(false)
    const [isThereError, setisThereError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [turnos, setTurnos] = useState("")
    const [diasObtenidos, setDiasObtenidos] = useState("")
    const [nroTurnoFinal, setNroTurnoFinal] = useState(0)
    const [horasObtenidas, setHorasObtenidas] = useState("")
    const [diaSeleccionado, setDiaSeleccionado] = useState("")
    const [redirigir, setRedirigir] = useState("")


    const setError = (mensaje) => {
        setisThereError(true)
        setErrorMessage(mensaje)
    }

    const unsetError = () => {
        setisThereError(false)
        setErrorMessage("")
    }

    useEffect(() => {
        const obtenerTurnosReprog = async () => {

            unsetError()
            setCargando(true)

            let url = 'https://rtogodoycruz.reviturnos.com.ar/api/auth/obtTurRep?tipo_vehiculo=' + props.vehiculo

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Authorization': 'Bearer ' + user.access_token
            }

            // let url = 'https://jsonplaceholder.typicode.com/todos/1';

            fetch(url, { method: 'GET', headers })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {

                            const dias = [];
                            respuesta.turnos.map((turno, i) => {
                                if (dias.find((reg) => reg === turno.fecha) === undefined) {
                                    dias.push(turno.fecha);
                                }
                            });
                            setDiasObtenidos(dias);
                            console.log(dias)
                            setTurnos(respuesta.turnos)
                            setCargando(false)
                            unsetError()
                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }
                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })
        }
        obtenerTurnosReprog()

    }, [])



    const handleChangeDay = (e) => {
        // setHoraSeleccionada("")
        setNroTurnoFinal(0)
        const horasDia = turnos.filter((reg) => reg.fecha === e.target.value);
        const horasDiaSinRepeticion = [];
        horasDia.map((horaDia, i) => {
            if (horasDiaSinRepeticion.find((reg) => reg.hora === horaDia.hora) === undefined) {
                horasDiaSinRepeticion.push(horaDia);
            }
        });
        setHorasObtenidas(horasDiaSinRepeticion)
        setDiaSeleccionado(e.target.value);



    }

    const handleClickTurnoElegido = (turno) => {

        // setHoraSeleccionada(turno.hora)
        setNroTurnoFinal(turno.id)
        console.log(turno.id)
        console.log(props.id_anterior)

        const reprogramarTurno = async () => {

            unsetError()
            setCargando(true)

            let url = 'https://rtogodoycruz.reviturnos.com.ar/api/auth/repTur'

            const { user } = JSON.parse(localStorage.getItem("auth"))
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.access_token
            }

            const datos = {
                id_turno_ant: props.id_anterior,
                id_turno_nuevo: turno.id
            }

            fetch(url, { method: 'POST', headers, body: JSON.stringify(datos) })
                .then(async res => {

                    if (res.status === 200) {
                        const respuesta = await res.json();

                        if (respuesta) {


                            console.log(respuesta)
                            setCargando(false)
                            unsetError()
                            const redireccion = "/buscarturno?id_turno=" + turno.id
                            setRedirigir(redireccion)
                        }


                    } else {
                        if (res.status === 500) {
                            logout(history)
                        }
                        const error = await res.json();
                        setCargando(false)
                        setError(error.mensaje)

                    }

                })
                .catch((error) => {
                    setCargando(false)
                    setError(error.message)
                })

        }
        reprogramarTurno()

    }



    return (
        <div className="card p-4" style={{ textAlign: "center" }}>
            {
                cargando && <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>


                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            {diasObtenidos && <>

                <p style={{ marginBottom: 0 }}>Seleccione un dia</p>

                <br />
                <select onChange={handleChangeDay} style={{ width: "200px", margin: "0 auto" }} className="form-control" id="exampleFormControlSelect1">
                    <option value="">Desplegar</option>
                    {diasObtenidos.map((dia, indice) => {
                        return <option key={indice} >{dia}</option>
                    })}
                </select>
            </>}

            {horasObtenidas && <>
                <br />
                <p style={{ marginBottom: 0 }}>Seleccione un horario:</p>
                <div>
                    {
                        horasObtenidas.map((turno, indice) => {
                            return <button onClick={() => handleClickTurnoElegido(turno)} style={{ fontSize: "14px", width: "56px", margin: "5px", padding: "2px" }} className="btn btn-light" key={indice} value={turno.hora}>{turno.hora.substr(0, 5)}</button>
                        })
                    }
                </div>
            </>}

            {
                isThereError && <>
                    <div style={{ position: "relative" }} className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div></>
            }

            {
                redirigir && <Redirect to={redirigir} />
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(ForcedLogOutAuthAction(history));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reprogturno);
