import React, { useEffect } from 'react'
import Itemturnomin from '../itemturnomin/itemturnomin'

function Listadoturnos({ turnos }) {

    useEffect(() => {

    }, [turnos])


    return (
        <ul>
            {turnos.map((turno, indice) => {
                return <Itemturnomin key={indice} datos={turno} />
            })}
        </ul>
    )
}

export default Listadoturnos;
