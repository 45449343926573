import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Turnosdia from '../turnosdia/turnosdia'
import Crearturno from '../crearturno/crearturno'
import Buscarturno from '../buscarturno/buscarturno'
import Nav from '../nav/nav'

function Activeuser(props) {



    return (
        <div className="home-page">
            <div className="container">
                <Router>
                    <Nav />
                    <hr />
                    <Switch>
                        <Route path="/turnosdia" default component={Turnosdia} />
                        <Route path="/crearturno" component={Crearturno} />
                        <Route path="/buscarturno" component={Buscarturno} />
                        <Route component={Turnosdia} />
                    </Switch>
                </Router>
            </div>

        </div>
    )
}

export default Activeuser;
