import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'

function Itemturnomin({ datos }) {

    const [redirigir, setRedirigir] = useState("")
    const [existeCobro, setExisteCobro] = useState(false)



    useEffect(() => {



    }, [datos])


    const handleVerTurno = () => {
        setRedirigir("/buscarturno?id_turno=" + datos.id)
    }

    return (
        <>
            <div onClick={handleVerTurno} className="card p-3" style={{ display: "inline-block", width: "100%" }} >
                <div className="row">
                    <div className="col-md-3"><span style={{ display: "inline-block" }}><b>Dominio:</b>&nbsp;{datos.datos.dominio}&nbsp;&nbsp;</span></div>
                    <div className="col-md-3"><span style={{ display: "inline-block" }}><b>Nombre:</b>&nbsp;{datos.datos.nombre}&nbsp;&nbsp;</span></div>
                    <div className="col-md-3"><span style={{ display: "inline-block" }}><b>Estado:</b>&nbsp;
                    {
                            datos.estado === "C" && 'Impago'
                        }
                        {
                            (datos.estado === "P"
                                || datos.estado === "A"
                                || datos.estado === "K"
                                || datos.estado === "F") && 'Pago'
                        }
                    &nbsp;&nbsp;</span></div>
                    <div className="col-md-3"><span style={{ display: "inline-block" }}><b>Hora:</b>&nbsp;{datos.hora}&nbsp;&nbsp;</span></div>
                </div>







            </div>
            <br /><br />

            { redirigir && <Redirect to={redirigir} />}

        </>
    )
}

export default Itemturnomin;
