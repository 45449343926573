import React from "react";
import { connect } from "react-redux";
import { Link, useHistory, Redirect } from "react-router-dom";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Activeuser from '../activeuser/activeuser'
import Notactiveuser from '../notactiveuser/notactiveuser'

function Home(props) {

  const { authState } = props

  return (
    <>
      <Header></Header>

      {authState.isLoggedIn ?

        <Activeuser />
        :
        <Redirect to="/login" />

      }

      <Footer></Footer>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.authState,
//   };
// };

const mapStateToProps = state => ({
  authState: state.authState
})

const mapDispatchToProps = (dispatch) => {
  return {
    // logout: (history) => {
    //   dispatch(LogOutAuthAction(history));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
