import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import Registropago from '../registropago/registropago'
import Reprogturno from '../reprogturno/reprogturno'

function Itemturno({ datos }) {

    const [redirigir, setRedirigir] = useState("")
    const [existeCobro, setExisteCobro] = useState(false)
    const [mostrarPagar, setMostrarPagar] = useState(false)
    const [mostrarReprog, setMostrarReprog] = useState(false)




    useEffect(() => {

        if (datos.estado === "P" || datos.estado === "A" || datos.estado === "F") {
            setExisteCobro(true)
        }

    }, [datos])




    const handleVerTurno = () => {
        setRedirigir("/buscarturno?id_turno=" + datos.id)
    }

    return (
        <>
            <div onClick={handleVerTurno}  >
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <b>Datos del turno</b>
                            </div>
                            <div className="card-body">
                                <span ><b>Nombre:&nbsp;</b>{datos.datos.nombre}&nbsp;&nbsp;</span><br />
                                <span ><b>Fecha:&nbsp;</b>{datos.fecha}&nbsp;&nbsp;</span><br />
                                <span ><b>Hora:&nbsp;</b>{datos.hora}&nbsp;&nbsp;</span><br />
                                <span ><b>Estado:&nbsp;</b>
                                    {
                                        datos.estado === "C" && 'Confirmado'
                                    }
                                    {
                                        datos.estado === "P" && 'Pago'
                                    }
                                    {
                                        datos.estado === "A" && 'Resultado - Apto'
                                    }
                                    {
                                        datos.estado === "K" && 'Resultado - Condicional'
                                    }
                                    {
                                        datos.estado === "F" && 'Resultado - Rechazado'
                                    }
                                &nbsp;&nbsp;</span><br />
                                <span ><b>Vehiculo:&nbsp;</b>{datos.datos.tipo_vehiculo}&nbsp;&nbsp;</span><br />
                            </div>

                        </div>


                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <b>Datos del vehiculo</b>
                            </div>
                            <div className="card-body">
                                <span ><b>Dominio:&nbsp;</b>{datos.datos.dominio}&nbsp;&nbsp;</span><br />
                                <span ><b>Marca:&nbsp;</b>{datos.datos.marca}&nbsp;&nbsp;</span><br />
                                <span ><b>Modelo:&nbsp;</b>{datos.datos.modelo}&nbsp;&nbsp;</span><br />
                                <span ><b>Año:&nbsp;</b>{datos.datos.anio}&nbsp;&nbsp;</span><br />
                                <span ><b>Combustible:&nbsp;</b>{datos.datos.combustible}&nbsp;&nbsp;</span>
                            </div>
                        </div>
                    </div>
                </div>

                {existeCobro && datos.cobro ? <><br /><div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <b>Datos del pago</b>
                            </div>
                            <div className="card-body">
                                <span ><b>Fecha:&nbsp;</b>{datos.cobro.fecha}&nbsp;&nbsp;</span><br />
                                <span ><b>Monto:&nbsp;</b>${datos.cobro.monto}.-&nbsp;&nbsp;</span><br />
                                <span ><b>Metodo:&nbsp;</b>{datos.cobro.metodo}&nbsp;&nbsp;</span><br />
                                <span ><b>ID de pago:&nbsp;</b>{datos.cobro.nro_op}&nbsp;&nbsp;</span><br />
                            </div>
                        </div>

                    </div>
                </div>
                    <br />
                    <button onClick={() => setMostrarReprog(!mostrarReprog)} className="btn btn-dark" type="button">Reprogramar turno</button>
                </>

                    : <><br /><button style={{ position: "relative", textAlign: "center" }} onClick={() => setMostrarPagar(!mostrarPagar)} className="btn btn-dark" type="button">Registrar pago</button></>
                }


            </div>

            {mostrarPagar && <><br /><Registropago id={datos.id} /></>}
            {mostrarReprog && <><br /><Reprogturno id_anterior={datos.id} vehiculo={datos.datos.tipo_vehiculo} /></>}

            { redirigir && <Redirect to={redirigir} />}

        </>
    )
}

export default Itemturno;
