import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { LogOutAuthAction } from "../../redux/actions/AuthAction";
import ErrorHandler from "../error/ErrorHandler";

function Header(props) {
  const { auth, logout, errorHandler } = props;
  const history = useHistory();
  return (
    <div style={{ zIndex: "10" }} className="header d-flex justify-content-center py-2 shadow-sm">
      <ErrorHandler
        errorHandler={errorHandler || { hasError: false, message: "" }}
      />
      <Link to="/">
        <h5 style={{ color: "#565758" }} className="font-weight-bold mx-3">RTVO Centro Express</h5>
      </Link>
      <div className="ml-auto d-flex">
        {!auth.isLoggedIn ? (
          <React.Fragment>
            {/* <Link to="./login">
              <button className="btn btn-danger btn-sm mx-2">Login</button>
            </Link> */}
            {/* <Link to="./register">
              <button className="btn btn-danger btn-sm mr-5">Sign up</button>
            </Link> */}
          </React.Fragment>
        ) : (
            <React.Fragment>
              <h5>{auth.user.name}</h5>
              <button
                className="btn btn-danger btn-sm mx-2"
                onClick={() => {
                  logout(history);
                }}
              >
                Salir
            </button>
            </React.Fragment>
          )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.authState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (history) => {
      dispatch(LogOutAuthAction(history));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
